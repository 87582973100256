import React, { useEffect, useState, useRef, useMemo } from "react";
import Scrollable from "./Scrollable";
import MessageItem from "./MessageItem";
import Loader from "./Loader";
import FileUploader from "./FileUploader";
import { PhotoProvider } from "react-photo-view";
import { RiCoupon2Fill, RiLoader2Fill } from "react-icons/ri";
import { GrSend } from "react-icons/gr";
import { useNavigate, useParams } from "react-router-dom";
import { HiTemplate } from "react-icons/hi";
import NoDialog from "./NoDialog";
import TemplateWindow from "./TemplateWindow";
import { FaChevronLeft } from "react-icons/fa";
import { IoBrowsersOutline } from "react-icons/io5";
import apiUrl from "./Api";
import PromoCreation from "./PromoCreation";

function Messages({
  messages,
  activeChat,
  setMessages,
  chats,
  setOverlayId,
  setActiveChat,
  setIsOverlayOpened,
}) {
  const messageArea = useRef(null);
  const navigate = useNavigate();

  const [text, setText] = useState("");
  const [messagesOnTop, setMessagesOnTop] = useState(2); // loading / yes / no
  const [messagesOnBottom, setMessagesOnBottom] = useState(2); // loading / yes / no
  const [isMessageSending, setIsMessageSending] = useState(false);

  const [isPromoOpened, setIsPromoOpened] = useState(false);

  const [isTemplateWindowOpened, setIsTemplateWindowOpened] = useState(false);

  const [notActive, setNotActive] = useState(true);

  const messagesRef = useRef(messages);

  const { chatId, messageId } = useParams();

  useEffect(() => {
    setTimeout(() => {
      document.getElementById(messageId)?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }, 500);
  }, [messageId]);

  useEffect(() => {
    messagesRef.current = messages;
  }, [messages]);

  useEffect(() => {
    if (activeChat) {
      onScrollTop(2, setMessagesOnTop);
      onScrollBottom(2, setMessagesOnBottom);
      setNotActive(null);
      setText("");
      setTimeout(() => {
        setNotActive(true);
      }, 5000);
    }
  }, [activeChat]);

  async function onScrollTop(messagesOnTop, setMessagesOnTop) {
    if (messagesOnTop === 2) {
      setMessagesOnTop(1);

      const loaded = await loadMessages(
        "old",

        messagesRef.current[0]?.id,
        messagesRef.current[messagesRef.current.length - 1]?.id,
        messagesRef.current.length
      );

      if (loaded.length === 0) {
        setMessagesOnTop(3);
      } else {
        setMessagesOnTop(2);
      }

      setMessages((prevMessages) => [...prevMessages, ...loaded]);
    }
  }

  async function onScrollBottom(messagesOnBottom, setMessagesOnBottom) {
    if (messagesOnBottom === 2) {
      setMessagesOnBottom(1);

      const loaded = await loadMessages(
        "new",

        messagesRef.current[0]?.id,
        messagesRef.current[messagesRef.current.length - 1]?.id,
        messagesRef.current.length
      );

      if (loaded.length === 0) {
        setMessagesOnBottom(3);
      } else {
        setMessagesOnBottom(2);
      }

      setMessages((prevMessages) => [...loaded, ...prevMessages]);
    }
  }

  async function loadMessages(direction, firstMessage, lastMessage, length) {
    return fetch(`${apiUrl}/chat/getMessages`, {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        chatId: activeChat,
        firstMessage,
        lastMessage,
        direction,
        length,
      }),
    })
      .then((r) => r.json())
      .then((r) => {
        if (r.statusCode && r.statusCode === 401) {
          navigate("/login");
        }
        if (r.statusCode && r.statusCode === 403) {
          return [];
        }
        return r;
      })
      .catch((e) => console.error(e));
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (text.replaceAll("\n", "") === "") return;
      if (
        !event.ctrlKey &&
        !event.shiftKey &&
        !/Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent)
      ) {
        sendMessage();
        messageArea.current.focus();
      } else {
        setText((text) => text + "\n");
      }
    }
  };

  function sendMessage() {
    if (!text) return;

    setIsMessageSending(true);
    fetch(`${apiUrl}/chat/sendMessage`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      method: "POST",
      body: JSON.stringify({
        chatId: activeChat,
        message: text,
      }),
    })
      .then(async (r) => {
        if ([200, 201].includes(r.status)) {
          setText("");
          messageArea.current.rows = 1;
        } else {
          r = await r.json();
          window.addPush(r.message);
        }
        setIsMessageSending(false);
      })
      .catch(() => setIsMessageSending(false));
  }

  const renderedMessages = useMemo(
    () =>
      messages.map((msg, idx) => (
        <MessageItem
          key={msg.id}
          setOverlayId={setOverlayId}
          active={notActive || messageId}
          message={msg}
          nextMessage={messages[idx + 1]}
        />
      )),
    [messages]
  );

  const calculateContentWidth = () => {
    if (!messageArea.current) return;

    const textarea = messageArea.current;
    const text = textarea.value;
    const style = window.getComputedStyle(textarea);

    const span = document.createElement("span");
    span.style.position = "absolute";
    span.style.whiteSpace = "pre-wrap";
    span.style.visibility = "hidden";

    span.style.font = style.font;
    span.style.letterSpacing = style.letterSpacing;
    span.style.wordSpacing = style.wordSpacing;
    span.style.textTransform = style.textTransform;

    span.textContent = text;

    document.body.appendChild(span);
    const width = span.offsetWidth;
    document.body.removeChild(span);

    return width;
  };

  const onChangeText = (e) => {
    setText(e.target.value);
  };

  if (!activeChat) {
    return <NoDialog />;
  }

  if (activeChat && messages.length === 0) return <Loader />;

  return (
    <div className="Messages">
      <div className="Header">
        <FaChevronLeft
          size={22}
          className="Back"
          onClick={() => {
            setActiveChat(null);
            navigate("/messenger");
          }}
        />
        <div className="Avatar">
          {chats
            .find((x) => x.id === activeChat)
            ?.name.slice(0, 1)
            .toUpperCase()}
        </div>
        <p>{chats.find((x) => x.id === activeChat)?.name}</p>
        <div className="Right" style={{ display: "flex", gap: "5px" }}>
          <RiCoupon2Fill
            size={22}
            onClick={() => {
              setIsPromoOpened((is) => !is);
            }}
          />
          <IoBrowsersOutline
            size={22}
            className={"Hidden"}
            onClick={() => {
              setIsOverlayOpened((is) => !is);
            }}
          />
        </div>
        {isPromoOpened ? (
          <PromoCreation setIsMenuOpened={setIsPromoOpened} />
        ) : null}
      </div>
      <Scrollable
        messagesOnTop={messagesOnTop}
        setMessagesOnTop={setMessagesOnTop}
        messagesOnBottom={messagesOnBottom}
        setMessagesOnBottom={setMessagesOnBottom}
        onScrollBottom={onScrollBottom}
        onScrollTop={onScrollTop}
      >
        <PhotoProvider>
          {messagesOnTop === 1 && (
            <Loader
              style={{ width: "fit-content", margin: "0 auto", height: "50px" }}
            />
          )}
          {renderedMessages}
          {messagesOnBottom === 1 && (
            <Loader
              style={{ width: "fit-content", margin: "0 auto", height: "50px" }}
            />
          )}
        </PhotoProvider>
      </Scrollable>

      <div className="Footer">
        <TemplateWindow
          setText={setText}
          isTemplateWindowOpened={isTemplateWindowOpened}
          setIsTemplateWindowOpened={setIsTemplateWindowOpened}
        />
        <div className="Container">
          <FileUploader activeChat={activeChat} />
          <textarea
            ref={messageArea}
            onKeyDown={handleKeyDown}
            spellCheck={true}
            value={text}
            disabled={isMessageSending}
            rows={
              text.includes("\n")
                ? 3
                : Math.min(
                    Math.trunc(
                      calculateContentWidth() / messageArea.current?.clientWidth
                    ) + 1,
                    3
                  )
            }
            onChange={onChangeText}
            placeholder={`Введите сообщение`}
          />

          {isMessageSending ? (
            <RiLoader2Fill
              size={24}
              style={{ animation: "spin 3s ease-in-out infinite" }}
            />
          ) : text === "" ? (
            <HiTemplate
              onClick={() => {
                setIsTemplateWindowOpened((is) => !is);
              }}
              size={24}
            />
          ) : (
            <GrSend onClick={sendMessage} size={24} />
          )}
        </div>
      </div>
    </div>
  );
}

export default Messages;
