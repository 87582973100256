import { FaDownload, FaUserEdit, FaUserPlus } from "react-icons/fa";
import "../css/Admin.css";
import ManageUser from "../components/ManageUser";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "../components/DatePicker";
import apiUrl from "../components/Api";

function convertTimeToString(seconds) {
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);
	const remainingSeconds = seconds % 60;

	const hourStr = hours > 0 ? `${hours} ч. ` : "";
	const minuteStr = minutes > 0 ? `${minutes} мин. ` : "";
	const secondStr = `${remainingSeconds} сек.`;

	return `${hourStr}${minuteStr}${secondStr}`.trim();
}

export default function Admin() {
	const navigate = useNavigate();
	const [users, setUsers] = useState([]);

	const [editUser, setEditUser] = useState({});
	const [isManageOpened, setIsManageOpened] = useState(false);

	const [logs, setLogs] = useState([]);

	const [selectedDateFrom, setSelectedDateFrom] = useState(new Date());
	const [selectedDateTo, setSelectedDateTo] = useState(new Date());

	function getUsers() {
		fetch(`${apiUrl}/users/getUsers`, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then(async (r) => {
				var rJson = await r.json();
				if (r.status === 201) {
					setUsers(rJson);
				} else {
					navigate("/login");
				}
			})
			.catch((error) => console.error("Error while getUsers:", error));
	}

	function getLogs() {
		fetch(`${apiUrl}/admin/getLogs`, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				dateFrom: selectedDateFrom.getTime(),
                dateTo: selectedDateTo.getTime(),
			}),
		})
			.then(async (r) => {
				var rJson = await r.json();
				if (r.status === 201) {
					setLogs(rJson);
				} else {
					navigate("/login");
				}
			})
			.catch((error) => console.error("Error while getLogs:", error));
	}

    function downloadLogs() {
        const link = document.createElement("a");
        link.href = `${apiUrl}/admin/downloadLogs?dateFrom=${selectedDateFrom.getTime()}&dateTo=${selectedDateTo.getTime()}`;
        link.download = `${selectedDateFrom.toDateString()}-${selectedDateTo.toDateString()}.xslx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

	useEffect(() => {
		getUsers();
		getLogs();
	}, []);

	useEffect(() => {
		getLogs();
	}, [selectedDateFrom, selectedDateTo]);

	return (
		<div className="AdminLayout">
			{isManageOpened ? (
				<ManageUser
					setIsManageOpened={setIsManageOpened}
					editUser={editUser}
					getUsers={getUsers}
				/>
			) : null}
			<div className="Header">
				<h1>Пользователи</h1>
				<FaUserPlus
					onClick={() => {
						setIsManageOpened(true);
						setEditUser({});
					}}
					size={24}
				/>
			</div>
			<div className="Users">
				{users.map((x, key) => (
					<div key={key} className="Item">
						<div className="MainInfo">
							<p>{x.username}</p>
							<p className="Status">
								{["Заблокирован", "Активен", "Администратор"][x.status - 1]}
							</p>
						</div>
						<div>
							<p style={{ fontSize: "14px", textAlign: "center" }}>AVG</p>
							<p style={{ fontSize: "12px", textAlign: "center" }}>
								~{convertTimeToString(Number(x.avgResponse))}
							</p>
						</div>
						<div className="Icons">
							<FaUserEdit
								onClick={() => {
									setEditUser(x);
									setIsManageOpened(true);
								}}
								size={24}
							/>
						</div>
					</div>
				))}
			</div>
			<div className="Header">
				<div className="WithDate">
					<h1>Логи</h1>
                    <p>От</p>
					<DatePicker
						setSelectedDate={setSelectedDateFrom}
						selectedDate={selectedDateFrom}
					/>
                      <p>До</p>
					<DatePicker
						setSelectedDate={setSelectedDateTo}
						selectedDate={selectedDateTo}
					/>
				</div>
				<FaDownload className="Download" onClick={() => { downloadLogs(); }} size={22} />
			</div>
			<div className="Logs">
				{logs.length === 0 ? (
					<p>За выбранный промежуток нет логов</p>
				) : (
					logs.map((x, key) => (
						<div className="LogsItem" key={key}>
							<p>{x.message}</p>
							<p style={{ textAlign: "right" }}>
								{new Date(x.timestamp).toLocaleString()}
							</p>
						</div>
					))
				)}
			</div>
		</div>
	);
}
