import { useEffect, useState } from "react";
import apiUrl from "./Api";

export default function PromoCreation({ setIsMenuOpened }) {
  const [promoMinus, setPromoMinus] = useState(0.05);
  const [activationLimit, setActivationLimit] = useState(1);

  const [promo, setPromo] = useState("");

  useEffect(() => {
    setPromo("");
  }, []);

  function createPromo() {
    fetch(`${apiUrl}/proxy/createPromo`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        promoMinus: promoMinus,
        uses: activationLimit,
      }),
    })
      .then((r) => r.json())
      .then((r) => {
        if (r.message) {
          try {
            if (r.promo) {
              navigator.clipboard.writeText(r.promo);
              setPromo(r.promo);
              window.addPush("Промокод скопирован в буфер обмена!");
            }
          } catch {}
        } else {
          window.addPush("Неизвестная ошибка");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  return (
    <div className="MicroMenu" style={{ right: 0, maxWidth: "350px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p>Процент промокода</p>
        <p>{promoMinus}</p>
      </div>
      <input
        type="range"
        onChange={(e) => setPromoMinus(e.target.value)}
        value={promoMinus}
        defaultValue={0.5}
        min={0.05}
        max={0.5}
        step={0.05}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <p>Лимит активаций</p>
        <p>{activationLimit}</p>
      </div>
      <input
        type="range"
        onChange={(e) => setActivationLimit(Number(e.target.value))}
        value={activationLimit}
        min={1}
        step={1}
      />
      <button
        onClick={() => {
          createPromo();
        }}
      >
        Создать
      </button>
      {promo.length > 0 ? (
        <input
          onClick={() => {
            navigator.clipboard.writeText(promo);
            window.addPush("Промокод скопирован в буфер обмена!");
          }}
          type={"text"}
          autofocus={true}
          value={promo}
        />
      ) : null}
      <button
        onClick={() => {
          setIsMenuOpened(false);
        }}
      >
        Закрыть
      </button>
    </div>
  );
}
