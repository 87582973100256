import { useEffect, useState } from "react";
import { GrClose } from "react-icons/gr";
import apiUrl from "./Api";

export default function ManageUser({ editUser, setIsManageOpened, getUsers }) {
  const [login, setLogin] = useState("");
  const [pass, setPass] = useState("");
  const [status, setStatus] = useState(1);

  const [tgId, setTgId] = useState("");

  const [workingHoursFrom, setWorkingHoursFrom] = useState("");
  const [workingHoursTo, setWorkingHoursTo] = useState("");

  useEffect(() => {
    if (!editUser.username) return;
    setLogin(editUser.username);
    setWorkingHoursFrom(editUser.workingHours[0]);
    setWorkingHoursTo(editUser.workingHours[1]);
    setStatus(editUser.status.toString());
    setTgId(editUser.tgId);
  }, [editUser]);

  function createUser() {
    if (
      login === "" ||
      pass === "" ||
      workingHoursFrom === "" ||
      workingHoursTo === ""
    )
      return window.addPush("Неа");

    fetch(`${apiUrl}/users/createUser`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: login,
        password: pass,
        status: status,
        workingHoursFrom: workingHoursFrom,
        workingHoursTo: workingHoursTo,
        tgId,
      }),
    })
      .then(async (r) => {
        var rJson = await r.json();
        if (r.status == 201) {
          getUsers();
          window.addPush(rJson.message);
          setIsManageOpened(false);
          setStatus(1);
          setLogin("");
          setPass("");
          workingHoursFrom("");
          workingHoursTo("");
        } else {
          window.addPush(rJson.message);
        }
      })
      .catch((error) => console.error("Error while getUsers:", error));
  }

  function modifyUser() {
    if (login === "" || workingHoursFrom === "" || workingHoursTo === "")
      return window.addPush("Неа");

    fetch(`${apiUrl}/users/editUser`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: editUser.id,
        username: login,
        password: pass,
        status: status,
        workingHoursFrom: workingHoursFrom,
        workingHoursTo: workingHoursTo,
        tgId,
      }),
    })
      .then(async (r) => {
        var rJson = await r.json();
        if (r.status == 201) {
          getUsers();
          window.addPush(rJson.message);
          setIsManageOpened(false);
          setStatus(1);
          setLogin("");
          setPass("");
          workingHoursFrom("");
          workingHoursTo("");
          setTgId("");
        } else {
          window.addPush(rJson.message);
        }
      })
      .catch((error) => console.error("Error while getUsers:", error));
  }

  return (
    <div className="Manage">
      <p>
        {editUser.username
          ? "Редактирование пользователя"
          : "Создание нового пользователя"}
      </p>
      <GrClose
        className="Close"
        onClick={() => {
          setIsManageOpened(false);
        }}
      />
      <div className="Ellipse1"></div>
      <input
        value={login}
        onChange={(e) => {
          setLogin(e.target.value);
        }}
        placeholder="Логин"
      />
      <input
        value={pass}
        onChange={(e) => {
          setPass(e.target.value);
        }}
        placeholder="Пароль"
      />
      <input
        value={tgId}
        onChange={(e) => {
          setTgId(e.target.value);
        }}
        placeholder="Telegram ID"
      />
      <p>Статус</p>
      <select
        value={status}
        onChange={(e) => {
          setStatus(e.target.value);
        }}
      >
        <option value={1}>Заблокирован</option>
        <option value={2}>Активен</option>
        <option value={3}>Администратор</option>
      </select>
      <p>Рабочее время</p>
      <input
        value={workingHoursFrom}
        onChange={(e) => {
          setWorkingHoursFrom(
            Number(
              Math.max(
                Math.min(0, e.target.value),
                Math.min(24, e.target.value)
              )
            ).toFixed(0)
          );
        }}
        placeholder="От"
        type="number"
        defaultValue={0}
        max={24}
        min={0}
        step={1}
      />
      <input
        value={workingHoursTo}
        onChange={(e) => {
          setWorkingHoursTo(
            Number(
              Math.max(
                Math.min(0, e.target.value),
                Math.min(24, e.target.value)
              )
            ).toFixed(0)
          );
        }}
        placeholder="До"
        type="number"
        defaultValue={24}
        max={24}
        min={0}
        step={1}
      />
      {editUser.username ? (
        <button
          onClick={() => {
            modifyUser();
          }}
        >
          Изменить
        </button>
      ) : (
        <button
          onClick={() => {
            createUser();
          }}
        >
          Создать
        </button>
      )}
    </div>
  );
}
