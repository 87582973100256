import { useEffect, useState } from "react";

import Chats from "../components/Chats";
import Messages from "../components/Messages";
import io from "socket.io-client";
import { useNavigate, useParams } from "react-router-dom";
import Overlay from "../components/Overlay";
import apiUrl from "../components/Api";

function Messenger() {
  const [socket, setSocket] = useState(null);

  const navigate = useNavigate();

  const { chatId } = useParams();

  const [chats, setChats] = useState([]);
  const [activeChat, setActiveChat] = useState(null);

  const [messages, setMessages] = useState([]);

  const [overlayId, setOverlayId] = useState("");
  const [isOverlayOpened, setIsOverlayOpened] = useState(false);

  function playNotify() {
    try {
      if (document.visibilityState === "visible") return;
      var notify = new Audio("/sound.mp3");
      notify.play();
      notify.remove();
    } catch {}
  }

  function loadChats() {
    fetch(`${apiUrl}/chat/getChats`, {
      method: "POST",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((r) => {
        if (r.message) {
          window.addPush(r.message);
        }
        if (r.statusCode && r.statusCode === 401) {
          navigate("/login");
        }
        return r;
      })
      .then((chats) =>
        setChats(
          chats.sort((a, b) => {
            const aTimestamp = new Date(a.messages.at(-1)?.timestamp).getTime();
            const bTimestamp = new Date(b.messages.at(-1)?.timestamp).getTime();
            return bTimestamp - aTimestamp;
          })
        )
      )
      .catch((error) => console.error("Error loading chats:", error));
  }

  useEffect(() => {
    //console.log("newchatid activeChat");
    //navigate(`/messenger/${activeChat}`)
    const socketIo = io("/ws", {
      transports: ["polling"],
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
    });

    setSocket(socketIo);

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        if (!socketIo.connected) {
          socketIo.connect();
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    loadChats();

    const handleNewMessage = (args) => {
      if (!args || !args.chat) return;
      if (!args.support) playNotify();
      const { chat } = args;
      const { id: chatId, name } = chat;

      if (activeChat === chatId) {
        setMessages((prevMessages) =>
          [args, ...prevMessages].sort((a, b) => b.timestamp - a.timestamp)
        );
      }

      setChats((prevChats) => {
        const chatIndex = prevChats.findIndex((x) => x.id === chatId);
        const updatedChats = [...prevChats];

        if (chatIndex !== -1) {
          updatedChats[chatIndex] = {
            ...updatedChats[chatIndex],
            messages: [...updatedChats[chatIndex].messages, args],
          };
        } else {
          updatedChats.push({
            id: chatId,
            name,
            messages: [args],
          });
        }

        return updatedChats.sort((a, b) => {
          const aTimestamp = new Date(a.messages.at(-1)?.timestamp).getTime();
          const bTimestamp = new Date(b.messages.at(-1)?.timestamp).getTime();
          return bTimestamp - aTimestamp;
        });
      });
    };

    socketIo.on("newMessage", handleNewMessage);

    return () => {
      socketIo.off("newMessage", handleNewMessage);
      socketIo.disconnect();
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [activeChat]);

  useEffect(() => {
    setActiveChat(chatId);
  }, []);

  return (
    <>
      <Overlay
        overlayId={overlayId}
        setOverlayId={setOverlayId}
        setIsOverlayOpened={setIsOverlayOpened}
        isOverlayOpened={isOverlayOpened}
      />
      <div className="Layout">
        <Chats
          chats={chats}
          setChats={setChats}
          activeChat={activeChat}
          setActiveChat={setActiveChat}
          setMessages={setMessages}
          setIsOverlayOpened={setIsOverlayOpened}
        />
        <div className={["Window", !activeChat ? "Hidden" : null].join(" ")}>
          <Messages
            setIsOverlayOpened={setIsOverlayOpened}
            setOverlayId={setOverlayId}
            messages={messages}
            setMessages={setMessages}
            activeChat={activeChat}
            setActiveChat={setActiveChat}
            chats={chats}
          />
        </div>
      </div>
    </>
  );
}

export default Messenger;
